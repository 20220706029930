
import {defineComponent, ref, onMounted} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportUserModal from "@/components/modals/forms/ExportUserModal.vue";
import AddUserModal from "@/components/modals/forms/AddUserModal.vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import users from "@/core/data/users";
import {IUser} from "@/core/data/users";

export default defineComponent({
  name: "view-users",
  components: {
    Datatable,
    ExportUserModal,
    AddUserModal,
  },
  setup() {
    const checkedUsers = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        key: "avatar",
      },
      {
        name: "userList.name",
        key: "name",
        sortable: true,
      },
      {
        name: "userList.mail",
        key: "email",
        sortable: true,
      },
      {
        name: "userList.projects",
        key: "projects",
        sortable: true,
      },
      {
        name: "userList.role",
        key: "role",
        sortingField: "role",
        sortable: true,
      },
      {
        name: "userList.created",
        key: "date",
        sortable: true,
      },
      {
        name: "actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IUser>>(users);
    const initUsers = ref<Array<IUser>>([]);

    onMounted(() => {
      setCurrentPageTitle("employees");
      initUsers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewUsers = () => {
      checkedUsers.value.forEach((item) => {
        deleteUser(item);
      });
      checkedUsers.value.length = 0;
    };

    const deleteUser = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initUsers.value);
      if (search.value !== "") {
        let results: Array<IUser> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      tableHeader,
      deleteUser,
      search,
      searchItems,
      checkedUsers,
      deleteFewUsers,
    };
  },
});
