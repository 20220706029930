interface IUser {
  id: number;
  avatar: string;
  name: string;
  email: string;
  projects: string;
  role: string;
  date: string;
}

const users: Array<IUser> = [
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-6.jpg",
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    projects: "-",
    role: "Projektleiter",
    date: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "",
    name: "Melody Macy",
    email: "melody@altbox.com",
    projects: "Project X",
    role: "Projektleiter",
    date: "01 Dec 2020, 10:12 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-1.jpg",
    name: "Max Smith",
    email: "max@kt.com",
    projects: "Bistro",
    role: "Projektleiter",
    date: "12 Nov 2020, 2:01 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-1.jpg",
    name: "Sean Bean",
    email: "sean@dellito.com",
    projects: "Astro, Project Y",
    role: "Mitarbeiter",
    date: "21 Oct 2020, 5:54 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-25.jpg",
    name: "Brian Cox",
    email: "brian@exchange.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "19 Oct 2020, 7:32 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "",
    name: "Mikaela Collins",
    email: "mikaela@pexcom.com",
    projects: "Projekt 4, Projekt 8",
    role: "Mitarbeiter",
    date: "23 Sep 2020, 12:37 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-9.jpg",
    name: "Francis Mitcham",
    email: "f.mitcham@kpmg.com.au",
    projects: "Projekt 3",
    role: "Mitarbeiter",
    date: "11 Sep 2020, 3:15 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "",
    name: "Olivia Wild",
    email: "olivia@corpmail.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "03 Sep 2020, 1:08 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-9.jpg",
    name: "Neil Owen",
    email: "owen.neil@gmail.com",
    projects: "Paramount",
    role: "Mitarbeiter",
    date: "01 Sep 2020, 4:58 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-23.jpg",
    name: "Dan Wilson",
    email: "dam@consilting.com",
    projects: "Projekt Y",
    role: "Mitarbeiter",
    date: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "",
    name: "Emma Bold",
    email: "emma@intenso.com",
    projects: "Project A",
    role: "Administrator",
    date: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-12.jpg",
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    projects: "Project X",
    role: "Mitarbeiter",
    date: "11 Aug 2020, 5:13 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-12.jpg",
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    projects: "-",
    role: "Mitarbeiter",
    date: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-11.jpg",
    name: "Melody Macy",
    email: "melody@altbox.com",
    projects: "Project 6",
    role: "Mitarbeiter",
    date: "01 Dec 2020, 10:12 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-9.jpg",
    name: "Max Smith",
    email: "max@kt.com",
    projects: "Project 3",
    role: "Mitarbeiter",
    date: "12 Nov 2020, 2:01 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-8.jpg",
    name: "Sean Bean",
    email: "sean@dellito.com",
    projects: "Project Y",
    role: "Mitarbeiter",
    date: "21 Oct 2020, 5:54 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-2.jpg",
    name: "Brian Cox",
    email: "brian@exchange.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "19 Oct 2020, 7:32 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-3.jpg",
    name: "Mikaela Collins",
    email: "mikaela@pexcom.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "23 Sep 2020, 12:37 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-4.jpg",
    name: "Francis Mitcham",
    email: "f.mitcham@kpmg.com.au",
    projects: "-",
    role: "Mitarbeiter",
    date: "11 Sep 2020, 3:15 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-5.jpg",
    name: "Olivia Wild",
    email: "olivia@corpmail.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "03 Sep 2020, 1:08 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-6.jpg",
    name: "Neil Owen",
    email: "owen.neil@gmail.com",
    projects: "Paramount",
    role: "Mitarbeiter",
    date: "01 Sep 2020, 4:58 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-7.jpg",
    name: "Dan Wilson",
    email: "dam@consilting.com",
    projects: "Project 4",
    role: "Mitarbeiter",
    date: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-8.jpg",
    name: "Emma Bold",
    email: "emma@intenso.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-8.jpg",
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "11 Aug 2020, 5:13 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-11.jpg",
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    projects: "-",
    role: "Mitarbeiter",
    date: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-10.jpg",
    name: "Melody Macy",
    email: "melody@altbox.com",
    projects: "Project X",
    role: "Projektleiter",
    date: "01 Dec 2020, 10:12 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-5.jpg",
    name: "Max Smith",
    email: "max@kt.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "12 Nov 2020, 2:01 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-7.jpg",
    name: "Sean Bean",
    email: "sean@dellito.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "21 Oct 2020, 5:54 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-1.jpg",
    name: "Brian Cox",
    email: "brian@exchange.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "19 Oct 2020, 7:32 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-13.jpg",
    name: "Mikaela Collins",
    email: "mikaela@pexcom.com",
    projects: "Project 5, Project 6, Project 7",
    role: "Mitarbeiter",
    date: "23 Sep 2020, 12:37 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-12.jpg",
    name: "Francis Mitcham",
    email: "f.mitcham@kpmg.com.au",
    projects: "-",
    role: "Mitarbeiter",
    date: "11 Sep 2020, 3:15 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-6.jpg",
    name: "Olivia Wild",
    email: "olivia@corpmail.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "03 Sep 2020, 1:08 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-8.jpg",
    name: "Neil Owen",
    email: "owen.neil@gmail.com",
    projects: "Paramount",
    role: "Mitarbeiter",
    date: "01 Sep 2020, 4:58 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-2.jpg",
    name: "Dan Wilson",
    email: "dam@consilting.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-4.jpg",
    name: "Emma Bold",
    email: "emma@intenso.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-7.jpg",
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "11 Aug 2020, 5:13 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-8.jpg",
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    projects: "-",
    role: "Mitarbeiter",
    date: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-7.jpg",
    name: "Dan Wilson",
    email: "dam@consilting.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-8.jpg",
    name: "Emma Bold",
    email: "emma@intenso.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-8.jpg",
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    projects: "Project 5",
    role: "Mitarbeiter",
    date: "11 Aug 2020, 5:13 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-11.jpg",
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    projects: "-",
    role: "Mitarbeiter",
    date: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-10.jpg",
    name: "Melody Macy",
    email: "melody@altbox.com",
    projects: "Project 4",
    role: "Mitarbeiter",
    date: "01 Dec 2020, 10:12 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-5.jpg",
    name: "Max Smith",
    email: "max@kt.com",
    projects: "Project 4",
    role: "Mitarbeiter",
    date: "12 Nov 2020, 2:01 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-7.jpg",
    name: "Sean Bean",
    email: "sean@dellito.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "21 Oct 2020, 5:54 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-1.jpg",
    name: "Brian Cox",
    email: "brian@exchange.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "19 Oct 2020, 7:32 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-13.jpg",
    name: "Mikaela Collins",
    email: "mikaela@pexcom.com",
    projects: "Facebook",
    role: "Mitarbeiter",
    date: "23 Sep 2020, 12:37 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-12.jpg",
    name: "Francis Mitcham",
    email: "f.mitcham@kpmg.com.au",
    projects: "Paypal",
    role: "Mitarbeiter",
    date: "11 Sep 2020, 3:15 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-6.jpg",
    name: "Olivia Wild",
    email: "olivia@corpmail.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "03 Sep 2020, 1:08 am",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-8.jpg",
    name: "Neil Owen",
    email: "owen.neil@gmail.com",
    projects: "Paramount",
    role: "Mitarbeiter",
    date: "01 Sep 2020, 4:58 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-2.jpg",
    name: "Dan Wilson",
    email: "dam@consilting.com",
    projects: "Project 8",
    role: "Mitarbeiter",
    date: "18 Aug 2020, 3:34 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-4.jpg",
    name: "Emma Bold",
    email: "emma@intenso.com",
    projects: "-",
    role: "Mitarbeiter",
    date: "14 Aug 2020, 1:21 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-7.jpg",
    name: "Ana Crown",
    email: "ana.cf@limtel.com",
    projects: "Project 5",
    role: "Mitarbeiter",
    date: "11 Aug 2020, 5:13 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    avatar: "media/avatars/300-8.jpg",
    name: "Emma Smith",
    email: "e.smith@kpmg.com.au",
    projects: "-",
    role: "Mitarbeiter",
    date: "14 Dec 2020, 8:43 pm",
  },
];

export { IUser };

export default users;
